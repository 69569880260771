<template>
<table class='bg-white mx-auto' style='width: 210mm'>
  <thead>
    <tr>
      <td>
        <header class='flex flex-row justify-between items-center border-b-2 pb-2'>
          <h3 class='text-lg'>투자심사위원회</h3>
          <img src='logo_timefolio.png' class='h-12 block' />
        </header>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div>
          <h1 class='w-full text-center mt-4 mb-8 text-5xl'>투자심의위원회 의사록</h1>
          <table class='border-collapse w-full'>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심업체명
              </td>
              <td class='border border-black p-2 align-top'>
                ㈜젠큐릭스
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심일시
              </td>
              <td class='border border-black p-2 align-top'>
                2021년 05월 24일 10시 00분
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심장소
              </td>
              <td class='border border-black p-2 align-top'>
                타임폴리오자산운용 본사 19층 대회의실
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                담당자
              </td>
              <td class='border border-black p-2 align-top'>
                이원석
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                참석자
              </td>
              <td class='border border-black p-2 align-top'>
                황성환, 안승우, 박중환, 이원석, 박현우
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심결과
              </td>
              <td class='border border-black p-2 align-top'>
                전원 찬성함
              </td>
            </tr>
          </table>

          <section class='mt-8'  style='page-break-after: always;'>
            <label>
              <input type='checkbox' />
              투자조건
            </label>
            <div class='border-2 border-black'>
              <table class='w-full'>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자기업명</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>(주)젠큐릭스</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자형태</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>신주 CB, 신주 CPS</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자예정일</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>2021년 06월 08일</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>총 발행금액</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>270억원 (CB 180억원, CPS 90억원)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>당사 투자금액</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>9억원 – CB 3억원, CPS 6억원<br>
                      (타 펀드 포함 총 45억원–CB 15억원, CPS 30억원)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>전환가능기간</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>CB:발행후1.0년~만기일1개월전/CPS:발행후1.0년~</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>전환단가</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>
                    CB: 22,191원 (2021년 05월 20일 기준 산정, 이사회결의에 따라 변동 가능)<br>
                    CPS: 19,971원 (10% 할인, 이사회결의에 따라 변동 가능)<br>
                    <div class='mt-2'>
                      <table class='border-collapse w-full'>
                        <tr>
                          <td class='border border-black p-2'>
                            1개월 가중산술평균주가(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,121
                          </td>
                          <td class='border border-black p-2'>
                            (A)
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            1주일 가중산술평균주가(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,658
                          </td>
                          <td class='border border-black p-2'>
                            (B)
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            최근일 가중산술평균주가(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            21,595
                          </td>
                          <td class='border border-black p-2'>
                            (C)
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            산술평균(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,125
                          </td>
                          <td class='border border-black p-2'>
                            (D)=((A)+(B)+(C)/3)
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            3거래일 가중산술평균주가(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,191
                          </td>
                          <td class='border border-black p-2'>
                            (E)
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            기준주가(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,191
                          </td>
                          <td class='border border-black p-2'>
                            (F) =MAX((C), (D), (E))
                          </td>
                        </tr>
                        <tr>
                          <td class='border border-black p-2'>
                            전환가액(원미만 절상)(원)
                          </td>
                          <td class='border border-black p-2 text-right'>
                            22,191
                          </td>
                          <td class='border border-black p-2'>
                            원단위 미만 절상
                          </td>
                        </tr>
                        </table>
                    </div>
                  </td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>전환시주식수</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>43,561주(CB 13,518주, CPS 30,043주) (운용사 타펀드 포함 총 217,811주) (이사회결의에 의한 전환단가에 따라 변동)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자밸류</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>pre 1,420억원 (‘21.05.18 종가기준)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>전환시지분율</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>0.56% (CB 0.17%, CPS 0.39%, 운용사 기보유물량 및 신규투자분 포함 총 4.56%, 신규발행 공동투자자 포함 기준)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>PUT/만기(CB)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>2년(2023-06-08) / 5년(2026-06-08)</td>
                  <td class='p-2 align-top'>존속기간(CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>5년(2026-06-08)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>Coupon/YTM(CB)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>1% / 1%</td>
                  <td class='p-2 align-top'>우선배당(CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>1%</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>Refixing(CB, CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>80%</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>Call 옵션(CB, CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>35%, YTC 2.0% (발행 후 1년 ~ 발행 후 2년)</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자금 용도</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>운영자금</td>
                </tr>
              </table>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              기타의견
            </label>
            <div class='p-4 border border-black'>
              본건 투자 관련 투자조건의 변경, 제반 계약체결, 투자실행 등에 관한 사항은 담당자(상무 박중환)가 간사(준법감시인)와 합의 후 진행
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              투자조건의 변경(or 사유)
            </label>
            <div class='p-4 border border-black'>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              주요쟁점
            </label>
            <div class='p-4 border border-black whitespace-pre'>
              - 유방암 예후진단 키트의 하반기 매출 본격화 추이와 관련해 추이 모니터링 필요<br><br>
              - 유방암 동반진단 임상 및 신제품(각종 암 동반진단·조기진단 키트)의 개발 및 허가 지연 가능성<br><br>
              - 최대주주 및 특수관계인의 지분율이 낮은 상태로, 관련한 모니터링 필요<br><br>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              자산배분
            </label>
            <div class='p-4 border border-black whitespace-pre'>
              ※ 배분 기준 <br>
              - 투자 건의 투자 단계 (비상장 벤처, pre-ipo, 상장사 메자닌 등)<br>
              - 펀드의 주목적 해당 여부 (주목적 해당 펀드에 우선 배정)<br>
              - 펀드 만기와 투자건의 회수 예상시점에 대한 부합 여부<br>
              - 펀드의 미투자자산을 고려한 투자가능재원 여부<br>
            </div>

            <div class='mt-8 p-4 border border-black whitespace-pre'>
              투자 재원 배분은 투자심사보고서에 기재한 바와 같이 집행
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              핵심운용인력의 해당 투자자산 편입 동의 여부
            </label>
            <table class='border-collapse w-full'>
              <tr class='bg-gray-300'>
                <td class='text-center p-2 border border-black'>운용역</td>
                <td class='text-center p-2 border border-black'>담당 펀드명</td>
                <td class='text-center p-2 border border-black'>동의 여부</td>
                <td class='text-center p-2 border border-black'>서명</td>
              </tr>
              <tr>
                <td class='text-center p-2 border border-black' rowspan='3'>이원석</td>
                <td class='text-center p-2 border border-black'>The Venture-V 2호 전문투자형사모투자신탁</td>
                <td class='text-center p-2 border border-black'>0</td>
                <td class='text-center p-2 border border-black' rowspan='3'></td>
              </tr>
              <tr>
                <td class='text-center p-2 border border-black'>메자닌알파 전문투자형사모투자신탁</td>
                <td class='text-center p-2 border border-black'>0</td>
              </tr>
              <tr>
                <td class='text-center p-2 border border-black'>코스닥벤처 Hedge-S 2호 전문투자형사모투자신탁</td>
                <td class='text-center p-2 border border-black'>0</td>
              </tr>
            </table>
          </section>

          <h1 class='w-full text-center mt-4 mb-8 text-5xl' style='page-break-before: always;'>투심위의견서</h1>
          <label class='block'>
            <input type='checkbox' />
            투심일시: 2021년 05월 24일 10:00
          </label>
          <label class='block'>
            <input type='checkbox' />
            투심업체명: ㈜젠큐릭스
          </label>

          <section style='page-break-after: always;'>
            <table class='border-collapse w-full'>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>투심위원</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>투심의견</td>
                <td class='border border-black p-2'>사유(조건부 및 반대시 의견개진)</td>
              </tr>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>황성환</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>찬성</td>
                <td class='border border-black p-2'>동사는 주력 제품 허가 이후부터 국내 영업조직을 꾸준히 확충해왔으며 작년부터 보험 적용 역시 가능해짐에 따라 올해부터 본격적인 매출 발생 가능성이 높다고 판단되어 본건 투자에 찬성함</td>
              </tr>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>안승우</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>찬성</td>
                <td class='border border-black p-2'>동사의 주력 제품인 유방암 예후진단 키트 시장은 현재 1개사가 거의 독 점 중인 바, 동사 제품의 시장 침투가 확대될 경우 성장 여력은 충분하다 고 판단되어 본건 투자에 찬성함</td>
              </tr>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>박중환</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>담당자</td>
                <td class='border border-black p-2'>본건 투자 담당자로 해당사항 없음</td>
              </tr>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>이원석</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>찬성</td>
                <td class='border border-black p-2'>동사는 현재 주력 제품인 예후진단 영역 외에도 동반진단, 조기진단, 모 니터링 등 진단 관련 전영역에 걸친 파이프라인을 보유하고 있어, 관련 긍정적 임상 결과 및 제품 허가 시 주가의 긍정적 흐름이 기대되어 본건 투자에 찬성함</td>
              </tr>
              <tr>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>박현우</td>
                <td class='border border-black p-2 whitespace-no-wrap text-center'>찬성</td>
                <td class='border border-black p-2'>동사는 본사의 주요 파이프라인 외에도 코스닥 상장사인 NGS 진단업체 엔젠바이오 및 액체생검 CTC 장비 업체 지노바이오, 체외진단장비 전문 업체 나노바이오라이프 등을 주요 자회사로 두고 있어 다양한 시너지가 기대되며 관련 이슈 부각 시 시장 주목도 상승이 가능할 것으로 판단되 어 본건 투자에 찬성함</td>
              </tr>
              </table>
          </section>

          <h3 class='text-center text-xl my-8'>본 건 투자의 심의 결과가 상기와 같음을 확인합니다.</h3>
          <h3 class='text-center text-xl my-8'>2021년 05월 24일</h3>
          <section>
            <table class='w-full border-collapse'>
              <tr>
                <td class='border border-black text-center p-2'>구분</td>
                <td class='border border-black text-center p-2'>성명</td>
                <td class='border border-black text-center p-2'>서명</td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2' rowspan='5'>심의위원</td>
                <td class='border border-black text-center p-2' style='height: 80px;'>황성환</td>
                <td class='border border-black text-center p-2'></td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2' style='height: 80px;'>안승우</td>
                <td class='border border-black text-center p-2'></td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2' style='height: 80px;'>박중환</td>
                <td class='border border-black text-center p-2'>해당사항 없음<br>(담당자)</td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2' style='height: 80px;'>이원석</td>
                <td class='border border-black text-center p-2'></td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2' style='height: 80px;'>박현우</td>
                <td class='border border-black text-center p-2'></td>
              </tr>
              <tr>
                <td class='border border-black text-center p-2'>간사</td>
                <td class='border border-black text-center p-2' style='height: 80px;'>김환웅</td>
                <td class='border border-black text-center p-2'></td>
              </tr>
            </table>
          </section>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
</table>
</template>

<script>

export default {
  name: 'ReportTest',
  props: ['investment'],
}
</script>

<style lang='scss' scoped>

@media print {
  @page {
    margin: 1cm;
  }
}
</style>
